import {
  getFormattedMoney,
  getPreliminaryInvoice,
  getPricingOption,
  getPricingOptions,
  getPricingOptionsRange,
  getTaxConfig,
  getTicketDefinitionCurrency,
  hasPricingOptions,
  isDonationTicketDefinition,
  PlaceWithTicketInfo,
  saleEnded,
  WIX_EVENTS_TICKET_DEFINITION_FQDN,
} from '@wix/wix-events-commons-statics'
import {Category, Place, SeatingReservationSummary, Section, Type} from '@wix/ambassador-seating-v1-seating-plan/types'
import {TFunction} from '@wix/yoshi-flow-editor'
import {PricingOption} from '@wix/ambassador-wix-events-web/types'
import {getTicketPriceText} from '../order-success'

export const isPlaceReservableAsWhole = (place: PlaceWithTicketInfo) => place?.reservationOptions?.reserveWholeElement

export const isPlaceInBasket = (place: PlaceWithTicketInfo) => place.quantity > 0

export const isPlaceArea = (place: Place) => place.elementType === Type.AREA

export const getElementLabelTitle = (elementType: Type, t: TFunction) => {
  const elementLabelHeaderMap = {
    [Type.AREA]: t('seatings_area'),
    [Type.ROW]: t('seatings_row'),
    [Type.TABLE]: t('seatings_table'),
    [Type.ROUND_TABLE]: t('seatings_table'),
  }

  return elementLabelHeaderMap[elementType]
}

export const getPlaceInfoText = ({sector, elementLabel, elementType, label}: PlaceWithTicketInfo, t: TFunction) => {
  const section = sector ? `${t('seatings_sector')} ${sector}` : null
  const elLabel = elementLabel ? `${getElementLabelTitle(elementType, t)} ${elementLabel}` : null
  const placeLabel = label ? `${t('seatings_seat')} ${label}` : null
  return `${[section, elLabel, placeLabel].filter(Boolean).join(' ')}`
}

export const getPlaceTicketPriceText = (ticket: wix.events.ticketing.Ticket, t: TFunction, priceOverride?: string) => {
  if (priceOverride) {
    return getTicketPriceText({amount: priceOverride, currency: getTicketDefinitionCurrency(ticket)}, t)
  } else if (isDonationTicketDefinition(ticket)) {
    return t('tickets.donation')
  } else if (hasPricingOptions(ticket)) {
    return getPricingOptionsRangeLabel(ticket, t)
  }
  return getTicketPriceText(ticket.price, t)
}

export const getPricingOptionsRangeLabel = (ticket: wix.events.ticketing.Ticket, t: TFunction) => {
  const priceRange = getPricingOptionsRange(ticket)
  return t('tickets.pricingOption.range', {
    min: getFormattedMoney(priceRange.min),
    max: getFormattedMoney(priceRange.max),
  })
}

export const getPricingOptionLabel = (pricingOption: PricingOption) =>
  `${pricingOption.name} - ${getFormattedMoney(pricingOption.price)}`

export const getActivePricingOptionLabel = (place: PlaceWithTicketInfo) => {
  const pricingOptionId = getActivePricingOptionId(place)
  const pricingOption = getPricingOption(place.ticket, pricingOptionId)
  return getPricingOptionLabel(pricingOption)
}

export const getWholeTablePricingOptionsLabels = (place: PlaceWithTicketInfo, t: TFunction) => {
  const selectedPricingOptionIds = getSelectedPricingOptionIds(place)
  const pricingOptions = getPricingOptions(place.ticket)
  const lines = pricingOptions
    .map(option => {
      const count = selectedPricingOptionIds.filter(id => id === option.id).length
      if (count) {
        const nameAndPrice = getPricingOptionLabel(option)
        if (count === 1) {
          return t('seating_tableReservation_pricingOptionSelection_singular', {nameAndPrice})
        } else {
          return t('seating_tableReservation_pricingOptionSelection_plural', {nameAndPrice, seatsNumber: count})
        }
      }
    })
    .filter(Boolean)
  return lines
}

export const getWholeTableReservationPrice = (event: wix.events.Event, place: PlaceWithTicketInfo, t: TFunction) => {
  const {ticket, places, donation, selectedPricingOptionIds} = place
  const isDonation = isDonationTicketDefinition(ticket)
  const isPricingOptions = hasPricingOptions(ticket)

  if (isDonation && !donation) {
    return ''
  }

  const getTotal = (priceOverrides: string[]) =>
    getPreliminaryInvoice([ticket], {[ticket.id]: {quantity: places.length, priceOverrides}}, getTaxConfig(event))
      .grandTotal

  if (isPricingOptions && !selectedPricingOptionIds) {
    const pricingRange = getPricingOptionsRange(ticket)
    const minTotal = getTotal(new Array(places?.length).fill(pricingRange.min.amount))
    const maxTotal = getTotal(new Array(places?.length).fill(pricingRange.max.amount))
    return t('seating_tableReservation_range', {min: getFormattedMoney(minTotal), max: getFormattedMoney(maxTotal)})
  }

  let priceOverrides: string[]
  if (isDonation) {
    priceOverrides = new Array(places?.length ?? 0).fill(donation ?? '0')
  } else if (isPricingOptions) {
    priceOverrides = selectedPricingOptionIds?.map(
      pricingOptionId => getPricingOption(ticket, pricingOptionId).price.amount,
    )
  }

  return getTicketPriceText(getTotal(priceOverrides), t)
}

export const getSelectedPricingOptionIds = (place: PlaceWithTicketInfo) => place.selectedPricingOptionIds
export const hasSelectedPricingOptions = (place: PlaceWithTicketInfo) =>
  Boolean(getSelectedPricingOptionIds(place)?.length)

export const getStrictPriceOptionId = (place: PlaceWithTicketInfo) => place.pricingOptionId
export const hasStrictPricingOption = (place: PlaceWithTicketInfo) => Boolean(getStrictPriceOptionId(place))

export const getActivePricingOptionId = (place: PlaceWithTicketInfo) =>
  getStrictPriceOptionId(place) ?? getSelectedPricingOptionIds(place)?.[0]

export const getPlaceInfoItems = (place: PlaceWithTicketInfo, t: TFunction): {label: string; value: string}[] => {
  const {sector, elementType, elementLabel, label, places} = place

  const placeInfo = [
    {label: t('seatings_sector'), value: sector},
    {label: getElementLabelTitle(elementType, t), value: elementLabel},
  ]

  if (isPlaceReservableAsWhole(place)) {
    placeInfo.push({label: t('seating_seatQuantity'), value: String(places.length)})
  } else if (!isPlaceArea(place)) {
    placeInfo.push({label: t('seatings_seat'), value: label})
  }

  return placeInfo
}

export const getTotalPlaceQuantity = (places: PlaceWithTicketInfo[], planPlaceId: string): number =>
  places
    .filter(({planPlaceId: filteredPlanPlaceId}) => filteredPlanPlaceId === planPlaceId)
    .reduce((sum, {quantity}) => sum + quantity, 0)

export const getPlaceStock = (places: PlaceWithTicketInfo[], place: PlaceWithTicketInfo) => {
  const {ticket} = place

  if (!ticket?.id || saleEnded(ticket)) {
    return 0
  }

  if (isPlaceReservableAsWhole(place) && place.places.some(item => !item.capacity)) {
    return 0
  }

  const stock = place.capacity - getTotalPlaceQuantity(places, place.planPlaceId)
  return stock < 0 ? 0 : stock
}

interface GetPlaceWithTicketInfoParams {
  place: Place
  category: Category
  sections: Section[]
  tickets: wix.events.ticketing.TicketDefinition[]
  t: TFunction
  seatingReservationSummary: SeatingReservationSummary
}

export const getPlaceWithTicketInfo = ({
  place: {id, label, capacity, elementType},
  category,
  sections,
  tickets,
  t,
  seatingReservationSummary,
}: GetPlaceWithTicketInfoParams): PlaceWithTicketInfo => {
  const {
    externalId,
    config: {color},
  } = category ?? {config: {}}
  const ticketDefinition = tickets.find(
    ticketDef => ticketDef.id === externalId?.replace(WIX_EVENTS_TICKET_DEFINITION_FQDN, ''),
  )
  const [sectorId, elementId] = id.split('-')
  const section = sections.find(item => item.id === Number(sectorId))
  const element = section.elements.find(item => item.id === Number(elementId))

  return {
    id,
    planPlaceId: id,
    elementType,
    label,
    color,
    capacity: capacity - seatingReservationSummary.places.find(({placeId}) => placeId === id).occupied,
    quantity: 0,
    sectorId,
    sector: sectorId === '0' ? undefined : section.title,
    elementLabel: element.title,
    elementId: element.id,
    ticket: ticketDefinition,
    ticketPrice: ticketDefinition ? getPlaceTicketPriceText(ticketDefinition, t) : null,
    donation: undefined,
    reservationOptions: element.reservationOptions,
  }
}

export const pricingOptionPlaceId = (id: string, pricingOptionId: string) => `${id}_${pricingOptionId}`
